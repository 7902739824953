
import { defineComponent, reactive, ref } from 'vue'

import Quotes from '@/components/Quotes.vue'
import Offer from '@/components/Offer.vue'
import Team from '@/components/Team.vue'
import Carousel from '@/components/Carousel.vue'
import Dot from '@/components/Dot.vue'

import { useFetchData } from '@/composables/useFetchData'
import { useSettings } from '@/composables/useSettings'

import { Quote } from '@/interfaces/Quote'

export default defineComponent({
    name: 'Home',
    components: {
        Quotes,
        Offer,
        Team,
        Carousel,
        Dot,
    },
    setup() {
        const { isMobile, isTablet, isDesktop, activeSection, changeActiveSection } = useSettings()
        const { fetchData, about, services } = useFetchData()
        const quoteHero = reactive<Quote>({
            textDesktop: `<p>"Im bardziej jest się uczciwym wobec siebie,</p>
            <p> tym większą ma się szansę na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            textTablet: `<p>"Im bardziej jest się uczciwym wobec</p>
            <p>siebie, tym większą ma się szansę</p>
            <p> na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            textMobile: `<p>"Im bardziej jest się uczciwym wobec</p>
            <p>siebie, tym większą ma się szansę</p>
            <p> na satysfakcjonujące i&nbsp;udane życie."</p>
             `,
            author: 'Nancy McWilliams',
        })
        const quoteOutro = reactive<Quote>({
            textDesktop: `<p>"Równowaga nie oznacza unikania konfliktów,</p>
            <p>równowaga to po prostu siła umożliwiającą przetrwanie</p>
            <p>przykrych emocji i radzenie sobie  z nimi."</p>`,
            textTablet: `<p>"Równowaga nie oznacza unikania konfliktów,</p>
            <p>równowaga to po prostu siła umożliwiającą</p><p>przetrwanie przykrych emocji</p><p> i radzenie sobie  z nimi."</p>`,
            textMobile: `<p>"Równowaga nie oznacza unikania</p><p> konfliktów, równowaga to po prostu siła</p><p> umożliwiającą przetrwanie przykrych</p><p> emocji i radzenie sobie  z nimi."</p>`,
            author: `M. Klein`,
        })

        fetchData()

        const scrollToSection = (sectionId: string) => {
            const element = document.querySelector(`#${sectionId}`)
            if (element) {
                window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY - 100, behavior: 'smooth' })
            }
        }

        return {
            about,
            services,
            quoteHero,
            quoteOutro,
            isMobile,
            isTablet,
            isDesktop,
            activeSection,
            changeActiveSection,
            scrollToSection,
        }
    },
})
